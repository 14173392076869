<template>
  <div>
    <v-container class="ma-0 pa-0" fluid>
      <v-row no-gutters align="center">
        <v-col cols="2" sm="4">
          <h2>{{ $t('PageTitles.Users') }}</h2>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            hide-details
            dense
            v-model="search"
            :label="$t('message.Search')"
            :class="$vuetify.breakpoint.mobile ? 'mt-4' : 'pa-4'"
            background-color="searchField"
          />
        </v-col>
        <v-col cols="12" md="4" align="right" v-if="isManager">
          <v-btn
            :block="$vuetify.breakpoint.mobile"
            :class="$vuetify.breakpoint.mobile ? 'my-4' : ''"
            :color="$vuetify.theme.dark ? 'black' : 'white'"
            depressed
            large
            @click="addUser"
          >
            <v-icon left>mdi-account-plus-outline</v-icon>
            {{ $t('ButtonsLabel.AddUser') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table :items="Users" :search="search" :loading="!UserFetched" :headers="getHeaders" @click:row="showUserDetails" :items-per-page="5" sort-desc>
        <template #[`item.name`]="{ item }">
          <router-link :to="{ name: 'UserDetails', params: { Id: item.id } }">{{ item.name }}</router-link>
        </template>
        <template #[`item.disabled`]="{ item }">
          {{ $util.formatDisabledState(item.disabled) }}
        </template>
      </v-data-table>
    </v-container>
    <create-edit-user ref="CreateEditUserRef" @user-updated="bubbleEmit" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiService from '@/services/apiService.js';
import CreateEditUser from '@/components/Dialogs/CreateEditUser.vue';

export default {
  components: { CreateEditUser },
  name: 'UserList',
  data: () => ({
    search: null,
    Users: [],
    UserFetched: false,
  }),
  computed: {
    ...mapGetters(['isManager']),
    getHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('UserListHeaders.Name'), value: 'name' },
        { text: t('UserListHeaders.Email'), value: 'email' },
        { text: t('UserListHeaders.PhoneNumber'), value: 'phoneNumber' },
        { text: t('UserListHeaders.CompanyName'), value: 'companyName' },
        { text: t('UserDetailsListHeaders.State'), value: 'disabled' },
      ];
    },
  },
  methods: {
    detailsView() {
      return this.$route.params.Id;
    },
    async GetUsers() {
      this.UserFetched = false;
      this.Users = await apiService.getUsers();
      this.UserFetched = true;
    },
    showUserDetails(user) {
      this.$router.push({ name: 'UserDetails', params: { Id: user.id } });
    },
    addUser() {
      this.$refs.CreateEditUserRef.createUser(null);
    },
    bubbleEmit() {
      this.GetUsers();
    },
  },
  created() {
    this.GetUsers();
  },
};
</script>